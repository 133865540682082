<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-4">
                        <div class="card-header mt--4 mb--4">
                            <div class="row">
                                <div class="col">
                                <h3 class="mb-0"> {{tt('mesc')}}</h3>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="search" :placeholder="tt('search')" v-on:keyup="filter"/>                         
                                    </div>
                                </div>
                                <div class="col text-right">
                                    <base-button size="sm" class="float-right" @click="showFormMainG">{{ tt('create') }}</base-button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <el-table
                                :data="tableData"
                                height="350px"
                                style="width:100%"
                                row-key="id"
                                lazy
                                class="table-responsive table-flush" 
                                header-row-class-name="thead-light"
                                :load="load"
                                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                            >
                                <el-table-column
                                    prop="code"
                                    :label="tt('code')"
                                    sortable
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="description"
                                    :label="tt('description')"
                                    sortable
                                >
                                </el-table-column>
                                
                                <el-table-column
                                    prop="action"
                                    width="100"
                                >
                                    <template v-slot="{row}">
                                        <el-dropdown trigger="click" class="dropdown" @command="handleTableAction">
                                            <span class="btn btn-sm btn-icon-only text-light">
                                                <i class="fas fa-ellipsis-v mt-2"></i>
                                            </span>
                                            <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                                <el-dropdown-item v-if="row.type != 'Sub Sub Group'" :command="{action:'add_child',id:row.id,type:row.type}">{{ tt('add') }}</el-dropdown-item>
                                                <el-dropdown-item :command="{action:'edit_child',id:row.id,code:row.code,description:row.description}">{{ tt('edit') }}</el-dropdown-item>
                                                <el-dropdown-item :command="{action:'delete_child',id:row.id,children:row.children}">{{ tt('delete') }}</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <validation-observer v-slot="{invalid}">
            <modal :show.sync="mescForm.mainGroup.show">
                <template slot="header">
                    <h5 class="modal-title">{{ tt('create_main_group_mesc')}}</h5>
                </template>
                <div>
                    <label class="form-control-label">{{ tt('code') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('code')" :placeholder="tt('code')" v-model="mescForm.mainGroup.code" rules="required"></base-input>
                </div>
                <div>
                    <label class="form-control-label">{{ tt('description') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('description')" :placeholder="tt('description')" v-model="mescForm.mainGroup.description" rules="required"></base-input>
                </div>
                <template slot="footer">
                    <base-button type="secondary" @click="mescForm.mainGroup.show = false">{{ tt('close')}}</base-button>
                    <base-button type="primary" :disabled="mescForm.mainGroup.loading || invalid" @click="save(null)">
                        <span v-if="mescForm.mainGroup.loading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                        <span v-else>{{ tt('save')}}</span>
                    </base-button>
                </template>
            </modal>    
        </validation-observer>

        <validation-observer v-slot="{invalid}">
            <modal :show.sync="mescChild.childGroup.show">
                <template slot="header">
                    <h5 class="modal-title" v-if="mescChild.childGroup.edit">{{ tt('edit_group_mesc')}}</h5>
                    <h5 class="modal-title" v-else>{{ tt('create_group_mesc')}}</h5>
                </template>
                <div>
                    <label class="form-control-label">{{ tt('code') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('code')" :placeholder="tt('code')" v-model="mescChild.childGroup.code" rules="required"></base-input>
                </div>
                <div>
                    <label class="form-control-label">{{ tt('description') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('description')" :placeholder="tt('description')" v-model="mescChild.childGroup.description" rules="required"></base-input>
                </div>
                <template slot="footer">
                    <base-button type="secondary" @click="mescChild.childGroup.show = false">{{ tt('close')}}</base-button>
                    <base-button type="primary" :disabled="mescChild.childGroup.loading || invalid" v-if="mescChild.childGroup.loading">    <span v-if="mescChild.childGroup.loading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span></base-button>
                    <base-button type="primary" :disabled="mescChild.childGroup.loading || invalid" v-else-if="mescChild.childGroup.edit == false && mescChild.childGroup.loading == false" v-on:click="save('saving')" ><span>{{ tt('save')}}</span></base-button>
                    <base-button type="primary" :disabled="mescChild.childGroup.loading || invalid" v-else-if="mescChild.childGroup.edit == true && mescChild.childGroup.loading == false" v-on:click="update"><span>{{ tt('update')}}</span></base-button>
                </template>
            </modal>    
        </validation-observer>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import Api from '@/helpers/api';
import Mesc from '@/services/dictionary/mesc.service';
export default {
    data(){
        return{
            search: '',
            tableData: [],
            mescForm:{
                mainGroup:{
                    show: false,
                    loading:false,
                    code:'',
                    description:''
                }
            },
            mescChild:{
                childGroup:{
                    show: false,
                    loading:false,
                    code:'',
                    description:'',
                    parent_id:'',
                    type:'',
                    id:'',
                    edit:false,
                }
            }
        }
    },
    mounted(){
        this.get();
    },
    methods: {
        showFormMainG(){
            this.mescForm.mainGroup.show = true;
        },
        addChildForm(row){
            this.mescChild.childGroup.show = true;
            this.mescChild.childGroup.parent_id = row.id
            this.mescChild.childGroup.type = row.type
        },
        handleTableAction(command){
            switch(command.action){
                case 'add_child':
                    this.mescChild.childGroup.show = true;
                    this.mescChild.childGroup.parent_id = command.id
                    this.mescChild.childGroup.type = command.type
                    this.mescChild.childGroup.edit = false;
                    break;
                case 'edit_child':
                    this.mescChild.childGroup.show = true;
                    this.mescChild.childGroup.id = command.id
                    this.mescChild.childGroup.code = command.code
                    this.mescChild.childGroup.description = command.description
                    this.mescChild.childGroup.edit = true
                    break;
                case 'delete_child':
                    this.deleteMainG(command.children,command.id);
                    break;
            }
        },
        get(){
            let context = this;
            this.tableData = []
            Api(context, Mesc.get({search: this.search})).onSuccess((response) =>{
                context.tableData = response.data.data;
            }).onError((error) =>{
                context.tableData = [];
            }).call()
        },
        save(row){
            this.mescForm.mainGroup.loading = true;
            this.mescChild.childGroup.loading = true;
            let context = this;
            let formData = new FormData();
            let checkMesc = false;
            if((this.mescForm.mainGroup.code != '' && this.mescForm.mainGroup.code.length == 2) || ( this.mescChild.childGroup.code != '' && this.mescChild.childGroup.code.length == 2)){
                for(var i = 0; i < this.tableData;i++){
                    if(this.tableData[i].code == this.mescForm.mainGroup.code){
                        checkMesc = true;
                    }else{
                        checkMesc = false;
                    }
                }
                

                if(checkMesc == false){
                    if(row == null){
                        formData.append('parent',0);
                        formData.append('code', this.mescForm.mainGroup.code);
                        formData.append('description', this.mescForm.mainGroup.description)
                    }else{
                        if(this.mescChild.childGroup.type == 'Main Group'){
                            formData.append('parent',1);
                        }else{
                            formData.append('parent',2);
                        }
                        formData.append('parent_id',this.mescChild.childGroup.parent_id)
                        formData.append('code', this.mescChild.childGroup.code);
                        formData.append('description', this.mescChild.childGroup.description)
                    }

                    Api(context, Mesc.save(formData)).onSuccess((response) =>{
                        context.get();
                        context.$notify({
                            message: response.data.message,                  
                            type: 'success',
                            verticalAlign: "bottom",
                            horizontalAlign: "left"
                        });
                    }).onError((error) => {
                        context.$notify({
                            message: error.response.data.message,                  
                            type: 'danger',
                            verticalAlign: "bottom",
                            horizontalAlign: "left"
                        });
                    }).onFinish(() =>{
                        context.mescForm.mainGroup.show = false;
                        context.mescChild.childGroup.show = false;
                        context.mescForm.mainGroup.loading = false;
                        context.mescChild.childGroup.loading = false;
                        context.mescChild.childGroup.code = '';
                        context.mescChild.childGroup.description = '';
                        context.mescChild.childGroup.parent_id = '';
                        context.mescForm.mainGroup.code = '';
                        context.mescForm.mainGroup.description = '';
                    }).call()
                }else{
                    this.$notify({
                        message: 'Code Main Group Already Exits',                  
                        type: 'danger',
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                    this.mescForm.mainGroup.show = false;
                    this.mescChild.childGroup.show = false;
                    this.mescForm.mainGroup.loading = false;
                    this.mescChild.childGroup.loading = false;
                    this.mescChild.childGroup.code = '';
                    this.mescChild.childGroup.description = '';
                    this.mescChild.childGroup.parent_id = '';
                    this.mescForm.mainGroup.code = '';
                    this.mescForm.mainGroup.description = '';
                }
            }else{
                this.$notify({
                    message: 'The character in MESC Code is a Maximum of 2 ',                  
                    type: 'danger',
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                });
                this.mescForm.mainGroup.loading = false;
                this.mescChild.childGroup.loading = false;
            }
        },
        update(){
            let context = this;
            if((this.mescChild.childGroup.code  != '' && this.mescChild.childGroup.description != '' && this.mescChild.childGroup.id != '') && (this.mescChild.childGroup.code.length == 2)){
                let formData = new FormData();
                formData.append('code', this.mescChild.childGroup.code);
                formData.append('description', this.mescChild.childGroup.description);
                formData.append('id', this.mescChild.childGroup.id);
                Api(context, Mesc.update(formData)).onSuccess((response) =>{
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    }); 
                }).onError((error) =>{
                    context.$notify({
                        message: context.tt('error_message'),                  
                        type: 'danger',
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                }).onFinish(()=>{
                    context.mescChild.childGroup.show = false;
                    context.mescChild.childGroup.loading = false;
                    context.mescChild.childGroup.code = '';
                    context.mescChild.childGroup.description = '';
                    context.mescChild.childGroup.parent_id = '';
                    context.mescChild.childGroup.edit = false;
                }).call()
                
            }
        },
        deleteMainG(children,id){
            if(!Array.isArray(children)){
                let context = this;
                this.confirmDialog(this.tt("confirm_delete")).then((result) =>{
                    if(result.value){
                        Api(context, Mesc.deleteChild(id)).onSuccess((response) =>{
                            context.get();
                            context.$notify({
                                message: response.data.message,                  
                                type: 'success',
                                verticalAlign: "bottom",
                                horizontalAlign: "left"
                            });
                        }).onError((error) => {
                            context.$notify({
                                message:  error.response.data.message,                  
                                type: 'danger',
                                verticalAlign: "bottom",
                                horizontalAlign: "left"
                            });
                        }).call();
                    }
                })
            }else{
                this.$notify({
                    message: 'Sorry, Data have a Children',                  
                    type: 'danger',
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                });
            }
        },
        load(tree, treeNode, resolve) {
            setTimeout(() => {
                let context = this;
                Api(context, Mesc.getChildren(tree.id)).onSuccess((response) => {
                    resolve(response.data.data);
                }).onError((error) =>{

                }).call()
            }, 100)
        },
        filter() {
            clearTimeout(this.loadTimeout);
            this.loadTimeout = setTimeout(() => {
                this.get()
            }, 1000);
        },
    }
}
</script>
