import Service from "@/helpers/service";

export default {
  get(params) {
    return Service().get("Mesc", {params});
  },
  getChildren(parent) {
    return Service().get("Mesc/children/" + parent);
  },
  save(params) {
    return Service().post("Mesc/create", params);
  },
  deleteChild(id) {
    return Service().post("Mesc/delete/" + id);
  },
  update(params) {
    return Service().post("Mesc/update", params);
  },
  search(params) {
    return Service().post("Mesc/search", params);
  },
  getMainGroup(inc) {
    return Service().get("Mesc/get-main-group-by-inc/" + inc);
  },
  getSubGroup(inc, key) {
    return Service().get("Mesc/get-sub-group-by-inc/" + inc + "/" + key);
  },
  getSubSubGroup(key) {
    return Service().get("Mesc/get-sub-sub-group-by-key/" +key);
  }
};